import { markdown } from 'markdown'

const songs = [{
    downloadIndex: 0,
    title: "Extreme Demo",
    description: `
The latest tune before heading over to the UK. It's got a good feel to it and with a bit of work might end up quite good.

It's only the first demo, the drums were recorded last, of all things... so its a little out of sync. There was supposed to be a new recording of it by Christmas 2000. Unfortunately, me being in England and my gear in New Zealand makes it a little hard :)`,
    filesize: "6,228 KB",
    filename: "extremedemo.mp3",
    soundcloud: {
      trackId: 528385245,
    },
  },

  {
    downloadIndex: 1,
    title: "AcoustiCone",
    description: `This is a very short acoustic demo. It was just a spur of the moment recording and came out really well. Its nice to listen to and there will definitely be a full length song written with it.`,
    filesize: "2,445 KB",
    filename: "acousticone.mp3",
    soundcloud: {
      trackId: 528385242,
    },
  },

  {
    downloadIndex: 2,
    title: "Msg To My Baby",
    description: `
This song was written right after splitting up a quite serious relationship, it's quite personal, but not necessarily the way I feel now.

It's the only song with lyrics I've recorded and they need some work, but it's okay for a listen as well.
      `,
    filesize: "3,240 KB",
    filename: "msgtomybaby.mp3",
    soundcloud: {
      trackId: 528385218,
    },
  },

  {
    downloadIndex: 3,
    title: "The Devil May Care",
    description: `
The second song written with "The Harry's" for the '97 Smokefree Rockquest. This is a more casual song, with some good steady riffs. It's also got to be one of my favourite feel-good drumming songs when playing it. It just gels.

It also has a proper lead solo, as opposed to the other crap that I spit out... when my fingers didn't work properly.

The Harry's were:
- Taui Edmonds (vocals)
- Luke Nutley (guitar)
- Tony Bryson (bass)
- Myself (drums)

This version is instrumental only and was recorded by multi-track years after leaving school.
        `,
    filesize: "2,495 KB",
    filename: "thedevilmaycare.mp3",
    soundcloud: {
      trackId: 528385236,
    },
  },

  {
    downloadIndex: 4,
    title: "The Fly",
    description: `
This was the first ambitious song written while in a band called "The Harry's" at Western Heights High School for the '97 Smokefree Rockquest. The guitar is a little bit inspired by Rage Against The Machine's Tom Morello as were the lyrics.

The lyrics (now lost in time somewhere) were based on the old skit "There's a fly in my soup!" and were rapped by lead singer and legend Taui Edmonds. I wish I had a recording with the lyrics, they were really funny.

The Harry's were:
- Taui Edmonds (vocals)
- Luke Nutley (guitar)
- Tony Bryson (bass)
- Myself (drums)

This version is instrumental only and was recorded by multi-track years after leaving school.

NB: There is actually no lead solo in this song... if you think you hear something on this track slightly resembling one, you are obviously crazy O_o
        `,
    filesize: "3,672 KB",
    filename: "thefly.mp3",
    soundcloud: {
      trackId: 528385233,
    },
  },

  {
    downloadIndex: 5,
    title: "From My Eyes",
    description: `
This song is nice and BOPPY. Its just got a steady 16beat rhythm and with a more powerful recording would be damned cool...

There has been a lot of good support for this song, so download it and have a listen.
        `,
    filesize: "4,630 KB",
    filename: "frommyeyes.mp3",
    soundcloud: {
      trackId: 528385224,
    },
  },

  {
    downloadIndex: 6,
    title: "First Ever Original Demo",
    description: `
This is the first real demo of 'the fly' and 'the devil may care' back-to-back, but has no bass guitar. Its a lot slower than the current version and is therefore quite interesting to compare.
        `,
    filesize: "7,008 KB",
    filename: "theharrys-firstdemo.mp3",
    soundcloud: {
      trackId: 528385215,
    },
  },
]

songs.forEach(song => {
  song.downloadPath = `/res/music/${song.filename}`
  song.description = markdown.toHTML(song.description)
})

export default songs
