import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Helmet from 'react-helmet'

import songs from '../../data/songs'

import ptOrange from '../images/pt_orange.gif'
import mp3Icon from '../images/icon_mp3.gif'
import downloadIcon from '../images/icon_download.gif'

const track_download = (event, href) => {
  const { ga } = window
  if (process.env.NODE_ENV === "production" && typeof ga === "function") {
    ga("send", "event", {
      eventCategory: "mp3",
      eventAction: "download",
      eventLabel: href,
    })
  }
}

const DownloadPage = () =>
  <Layout>
    <Helmet title="Hexy Music &gt; Download" />

    <main id="content">
      <h2>&raquo; Download</h2>

      <p>
        <img src={ptOrange} alt="" /> This page contains a selection of demos from various recording points since 1997.
        Feel free to download them and <Link to="/contact/" title="Contact Hexy Music">mail me</Link> your comments.
      </p>

      <div className="soundcloud-container">
        <iframe title="soundcloud" width="100%" height="450" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/642129522&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
      </div>

      {songs.map(song =>
        <div key={song.downloadIndex} id={`dl${song.downloadIndex}`} className="templateItem">
          <h3><img src={mp3Icon} width="16" height="16" alt={song.title} /> {song.title}</h3>

          <div className="description" dangerouslySetInnerHTML={{
            __html: song.description,
          }} />

          <div className="filesize">filesize: {song.filesize}</div>
          <div className="filelocation">
            <a href={song.downloadPath} onClick={e => track_download(e, song.downloadPath)}>
              <img src={downloadIcon} width="16" height="16" alt={`Download: ${song.filename}`} />
              {song.filename}
            </a>
          </div>
        </div>
      )}

      <p>*All material on this page is Copyright &copy; Dave Transom.<br />
      Click <Link to="/copyright/" title="Copyright information">here</Link> for more information.</p>
    </main>

    <aside id="ear">
      <div className="rich">
        <h3>&raquo; MP3's</h3>
        <ul className="orange">
          {songs.map(song =>
            <li key={song.downloadIndex}>
              <a href={`#dl${song.downloadIndex}`}>{song.title}</a>
            </li>
          )}
        </ul>
      </div>
    </aside>
  </Layout>

export default DownloadPage
